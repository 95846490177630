/* Расходящийся круг */
.circle {
  position: absolute;
  width: 0;
  height: 0;
  background: var(--dark-bg);

  border-radius: 50%;
}
.dark .circle {
  background: var(--light-grey);
}
.float-animation {
  opacity: 0.3;
  animation: touch-circle 0.6s ease-out;
}

@keyframes touch-circle {
  40% {
    opacity: 0.2;
  }
  100% {
    width: 800px;
    height: 800px;
    opacity: 0;
    margin: -400px;
  }
}
