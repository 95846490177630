:root {
  --white: #f7f9fc;
  --ultra-light: #f1f3f7;
  --super-light: #cfd7e3;
  --super-light-blue: #dde6ee;
  --light: #acb4c0;
  --super-dark: #1a1f22;
  --middle-grey: #34393f;
  --dark-grey-alt: #363c43;
  --grey: #50555c;
  --dark-blue-alt: #293041;
  --dark-text: #2d3342;
  --dark-bg: #141414;
  --dark-bg-alt: #212121;

  --dark-grey: #3b444d;
  --light-grey: #e4e6e7;
  --light-blue: #8197ce;
  --dark-green: #3c944c;
  --light-green: #6abd79;
  --dark-red: #e25858;
  --light-red: #f8b9b9;
  --dark-yellow: #ffa800;
  --light-yellow: #ffb629;
  --dark-sky: #2485da;
  --light-sky: #3391e4;
  --dark-black: #3a3a3a;
  --light-black: #5a5a5a;
}

::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color: var(--super-light);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--light);
  border-radius: 5px;
}

.dark ::-webkit-scrollbar-track {
  background-color: var(--dark-bg);
}

.dark ::-webkit-scrollbar-thumb {
  background-color: var(--dark-bg-alt);
}

p {
  font-size: 1.1em;
  line-height: 1.4em;
}

.dark p {
  color: var(--light);
}

a,
a:visited {
  color: var(--dark-sky);
}

h1 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: Play, sans-serif;

  font-size: 1.3em;
  line-height: 1em;
  font-weight: 700;
  text-decoration: none;
}

h1.active:hover {
  color: #474e5e;
  text-decoration: underline;
}
.dark h1 {
  color: var(--super-light);
  /* color: white; */
}

h2 {
  margin-top: 0px;
  margin-right: 5px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Play, sans-serif;
  font-size: 1.2em;
  line-height: 1.2em;
}

.dark h2 {
  color: var(--super-light);
}

h2.active:hover {
  text-decoration: underline;
}

h3 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: Play, sans-serif;
  font-size: 1.1em;
  line-height: 1.1em;
}
.dark h3 {
  color: var(--light-grey);
}

h3.white {
  color: #e4e4e4;
}

.dark input {
  color: var(--light);
}

.dark input::placeholder {
  color: var(--light);
}
.dark label {
  color: var(--light);
}

.super-title {
  font-family: Roboto, sans-serif;
  font-size: 4em;
  line-height: 1.8em;
  /* gradient font color from left to right */
  background: linear-gradient(90deg, #1750bb 0%, #ee2d13 30%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.utility-page-wrap {
  display: flex;
  width: 100vw;
  height: 100svh;
  max-height: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
}

.utility-page-content {
  display: flex;
  width: 260px;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.fullscreen {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  display: flex;
  overflow: auto;
  max-height: 100svh;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.left-menu {
  display: flex;
  overflow: auto;
  width: 200px;
  flex-direction: column;
  background-color: var(--dark-blue-alt);
}

.left-menu.dark {
  background-color: var(--dark-bg);
}

a.menu-item {
  display: flex;
  margin-bottom: 10px;
  padding: 5px 10px 5px 20px;
  align-items: center;
  text-align: center;
  color: var(--light);
  text-decoration: none;
  font-family: Roboto, sans-serif;
}

a.menu-item:hover {
  color: #dbe1eb;
}

.workspace-container {
  display: flex;
  overflow: auto;
  flex-direction: column;
  flex: 1;
  background-color: #f1f3f7;
}
.dark .workspace-container {
  background-color: var(--dark-bg-alt);
}

.menu-logo-container {
  display: flex;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 20px;
  line-height: 20px;
  text-decoration: none;
}
.menu-logo {
  width: 150px;
  height: 45px;
  background-position: 50% 50%;
  background-size: cover;
  background-image: url("https://branzy.club/static_files/branzy_logo_icon_white.png");
}
.menu-logo.dark {
  background-image: url("https://branzy.club/static_files/branzy_logo_icon_black.png");
}

.menu-items-container {
  padding-top: 50px;
  padding-bottom: 10px;
  flex: 1;
}
.menu-list {
  padding-left: 10px;
  list-style: none;
}

.projects-container {
  display: flex;
  padding: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.project-card-image {
  width: 150px;
  height: 100px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  align-items: flex-end;
  border-radius: 8px;
  background-position: 50% 50%;
  background-size: cover;
}

.project-card-wrap {
  position: relative;
  width: 450px;
  margin-right: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex: 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #cdd1e2;
  background-color: #fff;
  color: var(--dark-blue-alt);
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
}

.dark .project-card-wrap {
  background-color: var(--dark-bg-alt);
  border: 1px solid var(--dark-black);
  color: #fff;
}

.project-card-wrap.new {
  padding: 10px;
  height: 110px;
}

.dark .project-card-wrap:hover {
  border-color: var(--light-black);
  box-shadow: none;
}

.project-card-item {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.project-area {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: hidden;
  overflow-x: auto;
}

.alert-area {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  color: black;
  background: linear-gradient(to left, #31a4df, #25bba6);
  text-decoration: none;
  transition: opacity, 0.3s ease;
}

.alert-cover-zone {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 98;
  background-color: rgba(26, 26, 26, 0.639);
}

.notification-cleaner {
  position: fixed;
  right: 50px;
  bottom: 75px;
  z-index: 5;
  border-radius: 10px;
}

.notification-cleaner-animation-container {
  width: 100%;
  height: 200px;
  display: none;
}

.chat-container {
  /* контейнер списка чатов и самого чата */
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
}

.chat-list-wrapper {
  /* список чатов */
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 500px;
  padding-left: 7px;
  padding: 10px 5px 10px 10px;
}
.chat-list-body {
  overflow-x: auto;
}
.chat-list-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px 15px 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #cdd1e2;
  box-shadow: 1px 1px 3px 0 #e7e7e7;
  border-left: 7px solid #fff;
}

.chat-list-row:hover {
  background-color: #f4f6f8;
  border-left: 7px solid #f4f6f8;
}
.chat-list-row.active {
  border-left: 7px solid #478f9d;
}

.chat-list-message {
  max-height: 3.2em; /* Adjust the height as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.chat-letter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #478f9d;
  color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 1.2em;
  margin-right: 10px;
  text-transform: uppercase;
}

.chat-wrapper {
  /* контейрен самого чата */
  max-width: 500px;
  background-color: #478f9d;
  background-image: url("https://branzy.club/static_files/chat_wp_6.png");
  /* background-color: var(--dark-blue-alt); */
  padding-left: 7px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.chat-wrapper.animation-process {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

.chat-header {
  display: flex;
  padding: 5px 0px 5px 10px;
}
.chat-body {
  flex: 1;
  align-items: flex-end;
  padding: 5px;
  overflow-y: auto;
  overflow-x: none;

  /* transform: rotate(180deg);
  direction: rtl; */
}
.chat-message {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  /* выравниваю элементы внутри по правому краю */
  justify-content: flex-start;
  margin-bottom: 10px;
}
.chat-message.my {
  justify-content: flex-end;
}

.chat-message-body {
  background-color: #e1f4f8;
  /* background-color: #404b66; */
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  max-width: 300px;
}

.chat-message-body.my {
  background-color: #dcf8c6;
  /* background-color: #3171ae; */

  /* 6db9a5 */
  /* 63a2dc */
}

.chat-message-img {
  min-width: 30px;
  min-height: 30px;
  margin: 0 10px 5px 0;
  border-radius: 15px;
  background-position: 0px 0px;
  background-size: cover;
}
.chat-message-img.my {
  display: none;
}
.chat-message-date {
  font-size: 0.9em;
  line-height: 0.9em;
  color: #666;
  margin-top: 5px;

  text-align: right;
}

.chat-footer {
  display: flex;
  background-color: white;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.chat-notification-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 10px;
  right: 0px;
  bottom: 0px;
  width: 350px;
}
.chat-notification {
  position: relative;
  padding: 10px;
  background-color: #293041e0;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}
.chat-notification:hover {
  background-color: #293041ea;
}
.chat-notification .text {
  color: rgb(238, 238, 238);
}
.chat-notification-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.columns-wrapper {
  display: flex;
  padding: 10px;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;
}

.column {
  display: flex;
  width: 350px;
  max-height: 100%;
  min-width: 350px;
  margin-right: 10px;
  padding: 0px;
  flex-direction: column;
  border-radius: 5px;
  background-color: #e6e8f0;
  overflow: hidden; /* параметр должен фиксить проблему nested-скрола в beautifull-dnd */
}

.dark .column {
  background-color: var(--dark-bg);
  border: 1px solid var(--dark-bg-alt);
}

.column-body {
  overflow-y: auto;
  min-height: 30px;
  padding-right: 5px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.column-body::webkit-scrollbar {
  width: 10px;
  border-radius: 50%;
}

.column-base-nav {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 5px 10px;
  font-family: Play, sans-serif;
  color: #424558;
  font-size: 1.1em;
  line-height: 1.1em;
  font-weight: 700;
  cursor: pointer;
  border-radius: 20px;
  transition: opacity, 0.3s ease;
}

.column-base-nav.center {
  text-align: center;
}

.dark .column-base-nav {
  color: var(--light);
}

.column-base-nav:hover {
  background-color: #ffffff6d;
}

.column-base-nav.hidden {
  display: none;
}

.column-footer {
  display: none;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.column-footer.active {
  display: flex;
  text-decoration: none;
}

.column-header {
  padding: 5px 10px;
  justify-content: space-around;
  align-items: center;
}

.column-header.addcolumn {
  cursor: pointer;
}

.task {
  position: relative;
  width: 100%;
  margin-top: 5px;
  padding: 10px 18px 10px 10px;
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 #cecece;
  color: #3b5697;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
}

.task.horizontal {
  display: flex;
  flex-direction: row;
  flex: 0 auto;
  max-width: 800px;
  padding: 8px 10px 8px 10px;
}

.dark .task {
  background-color: var(--dark-bg-alt);
  box-shadow: none;
  border: 1px solid var(--dark-grey);
}

.task.strict {
  max-width: 300px;
  min-width: 250px;
}

.task-footer {
  display: block;
}

.task-footer-container {
  display: flex;
  padding-left: 0px; /* применяется для устранения отступа в списках */
  margin-bottom: 5px;
  margin-top: 5px;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
}
.task-status {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 10px;
  height: 10px;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 5px;
  background-color: #c7c7c7;
}

.task-status.green {
  background-color: #2cc76d;
}

.task-status.red {
  background-color: #e25858;
}

.task-status.yellow {
  background-color: #fcca35;
}

.task-status.blue {
  background-color: #3cb2cf;
}

.task-wrapper {
  position: relative;
  margin-right: 3px;
  padding: 5px 3px;
}
.task-column-label {
  padding-left: 5px;
  font-family: Play, sans-serif;
  color: var(--dark-blue-alt);
  font-size: 1em;
  line-height: 1.1em;
  font-weight: 700;
}
.dark .task-column-label {
  color: var(--light);
}

.task-body {
  position: relative;
  z-index: 2;
}

.task-bubble {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 35px;
  height: 35px;
  z-index: 1;
  background-color: #f36c3e;
  background: linear-gradient(146deg, #ffc05c 0%, #f26e46 100%);
  font-size: 1.5em;
  padding-top: 8px;
  padding-left: 8px;
  color: #fff;
  border-top-left-radius: 10px;
}

.minified-tasks-area {
  display: flex;
  flex-direction: row;
  height: 70px;

  padding: 3px 5px 3px 5px;
  overflow-x: hidden;
  background-color: #e6e8f0;
  /* horizotal align */
  justify-content: flex-start;
}
.dark .minified-tasks-area {
  background-color: var(--super-dark);
}

.minified-task {
  border-radius: 5px;
  background-color: white;
  transition: all 0.3s ease;
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
  max-width: 250px;
  overflow: hidden;
}
.dark .minified-task {
  color: var(--light);
  background-color: var(--dark-grey-alt);
}
.minified-task:hover {
  background-color: #ffffff83;
}

.minified-task-body {
  max-width: 200px;
  max-height: 43px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.avatar {
  position: relative;
  width: 30px;
  height: 30px;
  margin-top: 3px;
  margin-right: 3px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-position: 0px 0px;
  background-size: cover;
}

.avatar.letter {
  border: 1px solid #3a3a3a;
  background-color: #fff;
  color: #3a3a3a;
}

.avatar.letter.green {
  border-color: #3c944c;
  color: #3c944c;
}

.avatar.letter.orange {
  border-color: #dd9700;
  color: #dd9700;
}

.avatar.letter.red {
  border-color: #e25858;
  color: #e25858;
}

.avatar.letter.blue {
  border-color: #0a80a3;
  color: #0a80a3;
}

.avatar.letter.pink {
  border-color: #b402ae;
  color: #b402ae;
}

.avatar.big {
  width: 35px;
  height: 35px;
  margin-right: 5px;
  cursor: pointer;
  overflow: visible;
}
.avatar.small {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-top: 0px;
}

.avatar.marked {
  border-style: solid;
  border-width: 2px;
  border-color: #4dafe0;
}

.avatar.marked.green {
  border-color: #1fcf19;
}

.avatar.grouped {
  margin-right: -10px;
}

.top-menu {
  display: flex;
  max-height: 70px;
  min-height: 55px;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;
  background-color: #fff;
}
.dark .top-menu {
  background-color: var(--dark-bg-alt);
  border-bottom: 1px solid var(--dark-blue-alt);
}

.top-menu-nav {
  display: flex;
  padding: 5px 7px;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}

.top-menu-nav.mobile-only {
  display: none;
}

.top-menu-nav.min {
  flex: 0 auto;
}

.top-menu-nav.center {
  min-width: 400px;
  margin-right: auto;
  margin-left: auto;
  flex: 0 auto;
}

.top-menu-nav.start {
  max-width: 400px;
  cursor: pointer;
  flex: 0 auto;
}

.top-menu-dropdown {
  position: absolute;
  left: -50px;
  top: calc(100% + 5px);
  right: auto;
  bottom: auto;
  width: 400px;
  z-index: 11;
  border: 1px solid #c5c5c5;
  border-radius: 7px;
  background-color: #fff;
}
.dark .top-menu-dropdown {
  background-color: var(--dark-bg);
  border: 1px solid var(--grey);
}

.invisible-cover {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10;
}

.top-menu-search-wrapper {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
}

.footer-info {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px;
  flex-direction: row;
  justify-content: flex-start;
  list-style-type: none;
}

.footer-info-item {
  display: flex;
  margin-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  font-size: 0.9em;
  line-height: 1em;
  font-weight: 700;
  text-decoration: none;
}
.dark .footer-info-item {
  color: var(--light);
}

.footer-info-item.active {
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 6px;
  background-color: #4383e7;
  color: #fff;
}

.footer-info-item.active.success {
  background-color: #25af5f;
}

.task-cover-zone {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(26, 26, 26, 0.57);
}

.task-cover-zone.active {
  display: block;
  cursor: auto;
}

.dark .task-cover-zone {
  background-color: rgba(0, 0, 0, 0.8);
}

.task-modal {
  display: flex;
  width: 900px;
  margin-top: 50px;
  margin-bottom: 50px;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 1px 1px 7px 0 #575757;
}
.dark .task-modal {
  background-color: var(--dark-bg-alt);
  box-shadow: none;
  border: 1px solid var(--dark-grey);
}

.task-modal-wrapper {
  position: fixed;
  left: 50%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 11;
  display: block;
  overflow: auto;
  margin-left: -400px;
  padding-right: 20px;
  justify-content: center;
  align-items: flex-start;
}

.modal-header {
  padding: 5px 40px 10px 10px;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #f0f1f3;
}
.dark .modal-header {
  background-color: var(--dark-bg);
  border-bottom: 1px solid var(--dark-grey-alt);
}

.modal-content {
  overflow-y: auto;
  min-width: 400px;
  padding: 10px 20px;
  flex: 1;
}

.text {
  color: var(--dark-blue-alt);
  font-size: 1em;
  line-height: 1.4em;
}

.text.medium {
  font-size: 1.1em;
}

.text.small {
  font-size: 0.9em;
}

.text.small.dark-grey.active:hover {
  color: #525252;
}
.text.big {
  font-size: 1.3em;
}
.text.super-big {
  font-size: 3em;
}

.text.bold {
  font-weight: 700;
}

.text.bold.small {
  font-size: 0.9em;
}

.text.active {
  text-decoration: underline;
}

.text.active:hover {
  color: #6283d4;
}
.text.light {
  color: var(--ultra-light);
}

.text.icon-centered {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dark .text {
  color: var(--light);
}

.compare-strike {
  color: red;
}
.compare-bold {
  color: green;
}

.permit-text-selection {
  user-select: text;
  -webkit-user-select: text; /* Safari */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* IE/Edge */
}

.modal-body {
  display: flex;
  padding: 10px 10px 20px;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
}

.col.min {
  flex: 0 auto;
}

.col.col-6 {
  max-width: 60%;
  min-width: 60%;
}

.col.center {
  align-items: center;
}

.col.vertical-center {
  justify-content: center;
}

.col.scrollable {
  overflow: auto;
}

.col.col-4 {
  max-width: 40%;
  min-width: 40%;
}

.col.col-7 {
  max-width: 70%;
  min-width: 70%;
}

.col.col-3 {
  max-width: 30%;
  min-width: 30%;
}

.col.col-8 {
  max-width: 80%;
  min-width: 80%;
}

.col.col-2 {
  max-width: 20%;
  min-width: 20%;
}

.col.col-9 {
  max-width: 90%;
  min-width: 90%;
}

.col.col-1 {
  max-width: 10%;
  min-width: 10%;
}

.col.col-5 {
  max-width: 50%;
  min-width: 50%;
}

.files-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  flex: 1;
  border: 2px dashed rgba(255, 255, 255, 0);
  transition: border-color 0.3s ease;
}
.files-container.active {
  border-color: var(--light);
}

.file {
  display: flex;
  margin-top: 7px;
  margin-right: 5px;
  margin-left: 0px;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  font-size: 0.9em;
  font-weight: 400;
  text-decoration: none;
  width: 160px;
  cursor: pointer;
  border-radius: 10px;
  word-break: break-word;
}

.file:hover {
  background-color: white;
}
.dark .file:hover {
  background-color: var(--dark-grey-alt);
}

.file-body {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.file-image {
  width: 100px;
  height: 80px;
  margin-bottom: 5px;
  background-position: 50% 50%;
  background-size: contain;
}
.file-nav {
  margin-top: 10px;
}

.drop-file-container {
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 100px;
  border: 2px dashed var(--super-light);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  font-size: 1em;
  font-weight: 500;
  background-color: var(--super-light);
  color: var(--light);
}

.drop-file-container.active {
  border-color: var(--light);
}

.row {
  display: flex;
  padding: 5px 10px;
  flex-direction: row;
  flex: 0 1 auto;
}

.row.clear {
  padding-right: 0px;
  padding-left: 0px;
}

.row.min {
  padding: 0px;
}

.bold {
  font-weight: 700;
}
.dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scroll-to-messages {
  position: fixed;
  z-index: 11;
  bottom: 50px;
  right: 50px;
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 15px;
  background-color: #e4e6e7;
  box-shadow: 1px 1px 3px 0 #51515152;
}

.scroll-to-messages:hover {
  background-color: #d4d4d4;
}

.edit-message-settings {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: flex-end;
  align-items: center;
}

.messages-container {
  max-width: 800px;
  padding-right: 5px;
  padding-left: 5px;
}

.message {
  position: relative;
  display: flex;
  margin: 5px 0px;
  flex: 1;
  padding: 10px 5px;
  cursor: pointer;
}
.message.active {
  background-color: var(--super-light-blue);
  animation: disappearing-backgroung 7s ease-in-out forwards;
}

@keyframes disappearing-backgroung {
  0% {
    background-color: var(--super-light-blue);
  }
  80% {
    background-color: var(--super-light-blue);
  }
  100% {
    background-color: #ffffff00;
  }
}

.dark .message.active {
  background-color: var(--dark-grey-alt);
  animation: disappearing-background-dark 7s ease-in-out forwards;
}

@keyframes disappearing-background-dark {
  0% {
    background-color: var(--dark-grey-alt);
  }
  80% {
    background-color: var(--dark-grey-alt);
  }
  100% {
    background-color: #ffffff00;
  }
}

.message-img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 25px;
  background-position: 0px 0px;
  background-size: cover;
  background-color: #eeeeee;
}

.message-loader-bckgd {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: hsla(0, 0%, 60.7%, 0.61);
  color: #fff;
}

.message-name {
  margin-bottom: 5px;
}

.context-menu {
  position: fixed;
  min-width: 200px;
  flex-direction: row;
  background-color: white;
  top: -30px;
  z-index: 16;
  box-shadow: 1px 1px 4px 0 #cecece;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dark .context-menu {
  background-color: var(--dark-bg);
  box-shadow: none;
}

.context-menu-cover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 15;
}
.context-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
}
.context-item:hover {
  background-color: #a0a7ac32;
}
.context-emoji-line {
  display: flex;
  flex-direction: row;
}

.context-emoji {
  font-size: 1.5em;
  flex: 1;
  text-align: center;
  padding: 7px;
  cursor: pointer;
}

.context-emoji:hover {
  background-color: #e5eff6;
}
.emoji-wrapper {
  display: flex;
  margin-right: 5px;
  margin-top: 5px;
  justify-content: flex-start;
  align-items: center;
  flex: 0 auto;
  border: 1px solid #cad3da;
  border-radius: 10px;
  padding: 2px;
}
.dark .emoji-wrapper {
  border: 1px solid var(--dark-grey-alt);
}

.emoji {
  width: 20px;
  height: 20px;
  font-size: 1.4em;
  margin-right: 5px;
}

.emoji.active {
  animation: emoji-animation 1.5s ease-out forwards;
}

@keyframes emoji-animation {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
  40% {
    rotate: 15deg;
  }
  55% {
    rotate: -15deg;
  }
  70% {
    rotate: 0deg;
  }
  100% {
    transform: scale(1);
  }
}

.column-footer-form.active {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.column-footer-form {
  display: none;
  margin-bottom: 0px;
  padding: 10px 5px;
}

.column-footer-form.active {
  display: block;
}

.horizontal-form-container {
  display: flex;
  margin-bottom: 0px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.wr {
  padding: 5px;
  display: flex;
  flex-direction: row;
  flex: 0 auto;
}

.search-container {
  background-color: var(--ultra-light);
  padding: 5px 5px;

  border-bottom: 1px solid var(--super-light);
  max-height: 500px;
  overflow-y: auto;
}

.dark .search-container {
  background-color: var(--dark-bg-alt);
  border-bottom: 1px solid var(--dark-grey-alt);
  color: var(--light);
}
.search-list {
  margin: 0px;
  padding: 0px;
}
.search-item {
  margin-bottom: 5px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  list-style: none;
}

.search-item:hover {
  /* text-decoration: underline; */
  background-color: white;
}
.dark .search-item {
  border-bottom: 1px solid var(--dark-grey-alt);
}
.dark .search-item:hover {
  background-color: var(--dark-black);
}
.search-item.big {
  padding-bottom: 5px;
  padding-top: 5px;
}

.helper-container {
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  left: 0px;
  right: 0px;
  z-index: 11;
  min-height: 100px;
  max-height: 250px;
  border: 1px solid var(--super-light);
  background-color: #fff;
}

.dark .helper-container {
  background-color: var(--dark-bg-alt);
  border: 1px solid var(--dark-grey-alt);
}

.new-project {
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 #cecece;
}
.dark .new-project {
  background-color: var(--dark-bg);
  box-shadow: none;
}

.activenes-indicator {
  width: 10px;
  height: 10px;
  margin-right: 3px;
  border-radius: 10px;
  background-color: #ccc;
}

.activenes-indicator.red {
  background-color: #d62222;
}

.activenes-indicator.green {
  background-color: #2cc76d;
}

.activenes-indicator.blue {
  background-color: #0072d6;
}

.activenes-indicator.yellow {
  background-color: #fcca35;
}

.activenes-indicator.big {
  width: 15px;
  height: 15px;
}

.content-container {
  overflow: auto;
  flex: 1;
}

.project-card-info {
  display: flex;
  padding: 10px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  text-decoration: none;
  color: var(--dark-text);
}

.dark .project-card-info {
  color: var(--light);
}

.project-card-info.horizontal {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.modal-cover {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 20;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(26, 26, 26, 0.57);
}
.dark .modal-cover {
  background-color: rgba(26, 26, 26, 0.8);
}

.simple-modal {
  position: static;
  z-index: 21;
  display: flex;
  width: 400px;
  max-height: 90vh;
  margin: 10px auto;
  flex-direction: column;
  border-radius: 8px;
  background-color: #fff;
}
.dark .simple-modal {
  background-color: var(--dark-bg-alt);
  color: var(--light);
  border: 1px solid var(--dark-grey-alt);
}

.nav-list {
  padding-left: 0px;
  flex: 1;
}

.nav-list-item {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e2e2e2;
}

.nav-list-item:hover {
  background-color: #f5f5f5;
}

.nav-list-item:active {
  background-color: #ebe9e9;
}

.nav-list-item.selected {
  background-color: #ebe9e9;
}

.modal-footer {
  display: flex;
  padding: 10px 5px;
  justify-content: flex-end;
}

.select-item {
  display: flex;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #dadada21;
  font-size: 1em;
  line-height: 1.5em;
  cursor: pointer;
  color: var(--dark-text);
}

.select-item:hover {
  background-color: #7485a512;
}

.select-item.selected {
  background-color: var(--super-light);
}
.dark .select-item.selected {
  background-color: var(--dark-blue-alt);
}

.hidden-radio {
  display: none;
}

.active {
  cursor: pointer;
}

.mark {
  display: flex;
  height: 20px;
  min-width: 20px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f36c3e;
  background: linear-gradient(146deg, #ffc05c 0%, #f26e46 100%);
  color: #fff;
  font-size: 0.9em;
  line-height: 1em;
}

.content-wrapper {
  max-width: 700px;
  padding: 10px;
}

.login-wrapper {
  display: flex;
  width: 400px;
  padding-top: 30px;
  flex-direction: column;
  border-right: 1px solid #dbdbdb;
  background-color: #f1f3f7;
  background-image: url("https://branzy.club/static_files/login_background.jpg");
}

.login-body {
  display: flex;
  padding-top: 0px;
  padding-right: 10px;
  padding-left: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex: 1;
}

.login-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff88;
  border-radius: 10px;
}

.form {
  flex-direction: column;
  align-items: flex-end;
}

.subscriber {
  margin-bottom: 5px;
  padding: 5px;
  align-items: flex-start;
}

.subscriber:hover {
  box-shadow: 1px 1px 3px 0 #dfd4d4;
}

.subscriber.selected {
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 #dfd4d4;
}
.dark .subscriber.selected {
  background-color: var(--dark-bg-alt);
  box-shadow: none;
  border: 1px solid var(--dark-grey-alt);
}
.menu-project-info {
  padding-right: 10px;
  padding-left: 20px;
}

.menu-footer-container {
  padding-top: 20px;
  padding-right: 10px;
  padding-bottom: 20px;
}

.top-memu-text {
  padding-left: 10px;
  color: var(--dark-blue-alt);
  text-decoration: none;
}

.vertical-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.vertical-input {
  margin-bottom: 0px;
  border-top: 1px none #000;
  border-right: 1px none #000;
  border-left: 1px none #000;
  background-color: hsla(0, 0%, 100%, 0);
  font-size: 1.1em;
  line-height: 1.1px;
}

.horizontal-col {
  display: flex;
  margin-right: 10px;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}

.horizontal-col.min {
  flex: 0 auto;
}

.bottom-menu {
  display: none;
}

.toast {
  display: flex;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 7px 10px;
  float: left;
  align-items: center;
  border-style: solid;
  border-width: 1px 1px 1px 7px;
  border-color: #ececec #ececec #ececec #7c7c7c;
  background-color: #fff;
  color: #333;
  font-size: 0.9em;
  line-height: 0.9em;
  text-decoration: none;
}

.dark .toast {
  background-color: #363d4f;
  border: none;
  color: #e7e8ec;
}

.toast.primary {
  border-left-color: #4383e7;
}

.toast.danger {
  border-left-color: #c00;
}

.toast.success {
  border-left-color: #25af5f;
}

.change-wrapper {
  display: flex;
  margin-top: 5px;
  flex-direction: column;
  border: 1px none #dfdfdf;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 #e7e7e7;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.change-wrapper:hover {
  box-shadow: 1px 1px 4px 0 #aaa;
}

.dark .change-wrapper {
  background-color: var(--dark-bg-alt);
  box-shadow: none;
  border: 1px solid var(--dark-grey-alt);
}

.project-img {
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #dbdbdb;
  background-position: 0px 0px;
  background-size: cover;
}

.column-header-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
}

.search-form {
  position: relative;
  z-index: 9;
  display: flex;
  margin-bottom: 0px;
  flex: 1;
}

.search-settings-container {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 9;
  width: 480px;
  margin-top: 41px;
  border: 1px solid #c5c5c5;
  border-radius: 7px;
  background-color: #fff;
}

.dark .search-settings-container {
  background-color: var(--dark-bg);
  border: 1px solid var(--dark-bg-alt);
}

.search-cover {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 8;
  display: none;
  background-color: transparent;
}

.search-cover.active {
  display: block;
  background-color: rgba(0, 0, 0, 0.08);
}

.search-settings-body {
  overflow: auto;
  max-height: 70vh;
}

.search-settings-footer {
  display: flex;
  padding: 15px 10px;
  justify-content: flex-end;
}

.search-filter-wrapper {
  position: relative;
  z-index: 9;
  padding-left: 10px;
}

.heading {
  color: #727272;
  font-size: 30px;
}

.modal-topline {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #f3f0f0;
  max-height: 70px;
  min-height: 55px;
}
.dark .modal-topline {
  background-color: var(--dark-bg);
}

.login-footer {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ntfc-row {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  border-style: none none solid solid;
  border-width: 1px 1px 1px 5px;
  border-color: #dfdfdf;
  background-color: #fff;
  transition: all 0.3s ease;
}

.dark .ntfc-row {
  background-color: var(--dark-bg-alt);
  color: var(--light);
  box-shadow: none;
  border-bottom-color: var(--dark-bg-alt);
  border-left-color: var(--dark-bg);
}

.ntfc-row:hover {
  background-color: #e1e1e122;
}

.ntfc-row.active {
  border-left-color: #45c079;
  text-decoration: none;
}

.ntfc-body {
  display: flex;
  flex: 1;
  flex-direction: row;
  cursor: pointer;
  overflow: hidden;
  padding: 10px 10px;
}

.ntfc-body-limiter {
  max-height: 42px;
  margin-bottom: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.title {
  font-weight: 800;
}

.dark .title {
  color: var(--super-light);
  font-weight: 500;
}

.toast-wrapper {
  margin-top: 10px;
  padding-right: 10px;
}

.quote {
  display: flex;
  overflow: hidden;
  margin-top: 5px;
  padding: 5px 10px;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--dark-blue-alt);
  color: var(--ultra-light);
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
}

.answer-wrapper {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  border-left: 5px solid #dd9700;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 5px;
  display: -webkit-box;
}

.nav-elem {
  display: flex;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: #c5c5c5;
  font-size: 1.4em;
}

.nav-elem.active {
  color: #5e5e5e;
}

.modal-nav-container {
  display: flex;
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 5px;
  padding-left: 5px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  white-space: nowrap;
  cursor: pointer;
}

.modal-nav-container:active {
  background-color: #ddd;
}

.modal-nav-container.active {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f5f5f5;
}

.modal-nav-container.active:hover {
  background-color: #fff;
}

.modal-nav-container.active.mobile-only {
  display: none;
}

.modal-navigation {
  display: flex;
  padding: 10px;
  overflow-x: auto;
  overflow-y: visible;
}

.project-card-head {
  display: flex;
  text-decoration: none;
}

.modal-widgets {
  display: flex;
  padding: 5px 20px 10px;
  flex-wrap: wrap;
}

.list-container {
  padding-left: 0px;
  list-style-type: none;
}

.hero-heading {
  color: var(--dark-blue-alt);
  font-size: 1.2em;
  line-height: 1.3em;
  font-weight: 700;
}

.hero-heading.big {
  font-size: 2.5em;
}

.hero-wrapper {
  display: flex;
  padding: 30px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid #d6d6d6;
}

.hero-wrapper.big {
  padding-top: 100px;
  padding-bottom: 100px;
}

.hero-image {
  width: 300px;
  height: 200px;
  margin-right: auto;
  margin-left: auto;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.hero-center-wrapper {
  display: flex;
  height: 100svh;
  padding-right: 50px;
  padding-left: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-center-wrapper.grey {
  background-color: #f1f1f1;
}

.hero-center-wrapper.blue {
  background-color: #1d5383;
  color: #fff;
}

.hero-benefit {
  padding: 20px;
}

.hero-benefits-row {
  display: flex;
}
.modal-ref-container {
  position: relative;
  cursor: default;
}

.modal-ref-wrapper {
  display: flex;
  overflow: auto;
  padding: 10px 35px;
  scroll-behavior: smooth;
}

.modal-ref-wrapper::-webkit-scrollbar {
  display: none;
}

.modal-ref-scroller {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #61636d;
  font-size: 1.2em;
  width: 30px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(97, 99, 109, 0.078);
}

.modal-ref-scroller.left {
  left: 0px;
  right: auto;
}
.modal-ref-scroller:hover {
  cursor: pointer;
  background-color: rgba(97, 99, 109, 0.178);
}

.modal-ref-wrapper.project-card-style {
  background-color: #d8dcee;
  padding: 15px 35px;
}

.dark .modal-ref-wrapper {
  background-color: var(--dark-grey);
}

.modal-content-container {
  margin-right: 20px;
  margin-left: 20px;
  padding-top: 10px;
}
.dark .modal-content-container {
  color: var(--light);
}

.label {
  display: flex;
  margin-right: 5px;
  margin-left: -5px;
  padding: 5px;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  border-radius: 5px;
  font-family: Play, sans-serif;
  font-size: 0.9em;
  line-height: 1em;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}

.label.active {
  border-style: solid;
  border-width: 1px;
  border-color: #d4d4d4;
  background-color: #fff;
  cursor: pointer;
}
.dark .label.active {
  background-color: var(--dark-bg);
  border: none;
  color: var(--light);
}

.label.active:hover {
  box-shadow: 1px 1px 3px 0 #cfcfcf;
}
.label.active.dark:hover {
  box-shadow: none;
}

.template {
  display: flex;
  padding: 10px;
  text-align: left;
  align-items: center;
  font-family: Play, sans-serif;
  font-size: 0.9em;
  line-height: 1em;
  font-style: normal;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 155px;
}

.title-wrapper {
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
}

.modal-topline-wrapper {
  display: flex;
  padding-right: 15px;
  padding-left: 15px;
  align-items: center;
  flex: 1;
}

.color-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 20px;
  background-color: #ccc;
  cursor: pointer;
  color: var(--white);
  font-size: 1.3em;
}

.color-picker.red {
  background-color: #d62222;
}
.color-picker.dark-red {
  background-color: #9e1212;
}
.color-picker.electric-red {
  background-color: #ff3f8b;
}

.color-picker.blue {
  background-color: #0072d6;
}
.color-picker.dark-blue {
  background-color: #004886;
}
.color-picker.electric-blue {
  background-color: #00bfff;
}

.color-picker.green {
  background-color: #2cc76d;
  color: #2cc76d;
}
.color-picker.dark-green {
  background-color: #285f32;
}
.color-picker.electric-green {
  background-color: #00cec2;
}

.color-picker.orange {
  background-color: #e2830f;
}
.color-picker.dark-orange {
  background-color: #b66c11;
}
.color-picker.electric-orange {
  background-color: #ff8c00;
}

.color-picker.dark-grey {
  background-color: #727272;
}

.color-picker.yellow {
  background-color: #fcca35;
}
.color-picker.dark-yellow {
  background-color: #b18400;
}
.color-picker.electric-yellow {
  background-color: #ffff00;
}
.color-picker.violet {
  background-color: #9f78c1;
}
.color-picker.dark-violet {
  background-color: #6f4a8e;
}
.color-picker.electric-violet {
  background-color: #8a2be2;
}

.color-picker.small {
  width: 20px;
  height: 20px;
}

.color-picker.big {
  width: 45px;
  height: 45px;
  border-radius: 25px;
}

.red {
  color: #d62222 !important;
}
.dark .red {
  color: #db5b5b !important;
}
.dark-red {
  color: #9e1212 !important;
}
.dark .dark-red {
  color: #dd2d2d !important;
}
.electric-red {
  color: #ff3f8b !important;
}

.blue {
  color: #0072d6 !important;
}

.dark .blue {
  color: #569ddb !important;
}

.dark-blue {
  color: #004886 !important;
}
.dark .dark-blue {
  color: #0577d5 !important;
}
.electric-blue {
  color: #00bfff !important;
}

.green {
  color: #31b166 !important;
}
.dark .green {
  color: #4cb760;
}

.dark-green {
  color: #285f32 !important;
}
.dark .dark-green {
  color: #3a8748 !important;
}
.electric-green {
  color: #00cec2 !important;
}

.orange {
  color: #e2830f !important;
}

.dark-orange {
  color: #b66c11 !important;
}
.dark .dark-orange {
  color: #d17f0f !important;
}
.electric-orange {
  color: #ff8c00 !important;
}

.grey {
  color: #686868;
}
.dark .grey {
  color: #9c9c9c;
}
.dark-grey {
  color: #333 !important;
}
.dark .dark-grey {
  color: #717171 !important;
}

.yellow {
  color: #fcca35 !important;
}
.dark-yellow {
  color: #cf9b00 !important;
}

.violet {
  color: #9f78c1;
}

.dark-violet {
  color: #6f4a8e;
}
.electric-violet {
  color: #8a2be2;
}

.color-wrapper {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 3px;
  border-color: rgba(255, 255, 255, 0);
  border-radius: 25px;
}

.color-wrapper.selected {
  border-style: solid;
  border-color: #0072d6;
}

.color-container {
  display: inline-block;
}

.text-icon {
  width: 15px;
  height: 15px;
  justify-content: center;
  align-items: center;
  color: #3b5697;
}

.avatar-icon {
  position: absolute;
  left: auto;
  top: auto;
  right: -5px;
  bottom: -5px;
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #ffffff;
  z-index: 10;
}

.avatar-icon.animation-green {
  animation: grow-and-swing-to-green 1.5s ease-in-out forwards;
}
.avatar-icon.animation-blue {
  animation: grow-and-swing-to-blue 1.5s ease-in-out forwards;
}

@keyframes grow-and-swing-to-green {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
  40% {
    rotate: 15deg;
  }
  55% {
    rotate: -15deg;
    background-color: #79da76;
  }
  70% {
    rotate: 0deg;
    background-color: #79da76;
  }
  100% {
    transform: scale(1);
    background-color: #79da76;
  }
}

@keyframes grow-and-swing-to-blue {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
  40% {
    rotate: 15deg;
  }
  55% {
    rotate: -15deg;
    background-color: #4dafe0;
  }
  70% {
    rotate: 0deg;
    background-color: #4dafe0;
  }
  100% {
    transform: scale(1);
    background-color: #4dafe0;
  }
}

.avatar-icon.green {
  background-color: #79da76;
}

.avatar-icon.blue {
  background-color: #4dafe0;
}

.avatar-icon.green.shifted {
  right: 5px;
  background-color: #ffffff00;
  z-index: 11;
}

.avatar-icon.min {
  right: -2px;
  bottom: -2px;
  width: 10px;
  height: 10px;
}

.absolute-container {
  display: flex;
  overflow: auto;
  height: 100svh;
  flex-direction: column;
  flex: 1;
}

.avatar-blackout {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.37);
  color: #fff;
}

.modal-wrapper {
  position: fixed;
  left: 50%;
  top: 0%;
  bottom: 0%;
  z-index: 21;
  display: flex;
  margin-left: -200px;
  align-items: center;
}

.right-panel {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 11;
  display: none;
  width: 400px;
  flex-direction: column;
  background-color: var(--white);
}

.right-panel.active {
  display: flex;
}
.dark .right-panel {
  background-color: var(--dark-bg-alt);
}

.change-container {
  overflow: auto;
  padding: 10px;
  flex: 1;
}

.master-image {
  position: relative;
  width: 100%;
  height: 300px;
  max-width: 400px;
  border-radius: 5px;
  background-color: #e2e2e2;
  background-position: 50% 50%;
  background-size: cover;
}

.master-image-cover {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.33);
  color: #fff;
  font-size: 2em;
  cursor: pointer;
}

.login-support-wrapper {
  overflow: auto;
  flex: 1;
}

.login-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-only {
  display: none;
}

.send-message-wrapper {
  display: flex;
  padding: 15px 10px 0px 15px;
  align-items: flex-start;
}

.send-message-container {
  margin-bottom: 20px;
}

.message-input-wrapper {
  position: relative;
  padding-bottom: 20px;
}

.typing-container {
  position: absolute;
  bottom: 0px;
  left: 5px;
}

.modal-messages {
  overflow: auto;
  padding: 10px 20px;
}

.console-container {
  position: fixed;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #474747;
  color: #fff;
}

.editor-nav-wrapper {
  display: none;
  padding-left: 5px;
}

.editor-nav-wrapper.active {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  display: block;
}

.editor-nav {
  display: flex;
  width: 35px;
  height: 35px;
  margin-bottom: 3px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 #cecece;
}
.dark .editor-nav {
  box-shadow: none;
  background-color: var(--dark-grey-alt);
  color: white;
}

.editor-nav.is-active {
  background-color: #f1f1f1;
}

.dark .editor-nav.is-active {
  background-color: var(--grey);
}

.editor-wrapper {
  min-height: 300px;
  padding: 10px;
}

.editor-wrapper.active {
  flex: 1;
  /* background-color: #fff; */
  box-shadow: 1px 1px 3px 0 #cecece;
  cursor: auto;
}

.dark .editor-wrapper.active {
  border: 1px solid var(--dark-black);
  box-shadow: none;
}

.editor-container {
  position: relative;
  display: flex;
}

.strike {
  text-decoration: line-through;
}

.widget-item {
  margin-top: 5px;
}

.separator {
  position: relative;
  height: 15px;
  margin: 10px 5px;
  border-bottom: 1px solid #7890a5;
}

.text-block {
  border-bottom: 1px solid #000;
}

.separator-text {
  position: absolute;
  left: 9px;
  top: 5px;
  right: auto;
  bottom: auto;
  height: 20px;
  padding-right: 5px;
  padding-left: 5px;
  background-color: #fff;
  color: #004886;
  font-size: 0.9em;
  text-align: center;
}

.round-icon {
  position: relative;
}

.remove-cover {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 12;
  display: flex;
  padding-top: 5px;
  padding-right: 10px;
  justify-content: flex-end;
  align-items: flex-start;
  border: 1px solid #d62222;
  border-radius: 5px;
  background-color: rgba(214, 34, 34, 0.04);
  color: #ff2b00;
}

.settings-container {
  display: flex;
  overflow: hidden;
  flex: 1;
  background-color: #f1f3f7;
}
.dark .settings-container {
  background-color: var(--dark-bg);
}
.react-datepicker-popper {
  z-index: 9 !important;
}

.dropdown-menu {
  position: absolute;
  z-index: 11;
  bottom: 15px;
  right: 15px;
  min-width: 200px;
  max-width: 300px;
  background-color: white;
  box-shadow: 1px 1px 4px 0 #aaa;
  transition: all 0.3s ease;
}
.dark .dropdown-menu {
  background-color: var(--dark-bg);
  box-shadow: none;
  border: 1px solid var(--grey);
  color: var(--light);
}

.dropdown-element {
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
  cursor: pointer;
  padding: 10px;
}
.dropdown-element:hover {
  background-color: #d2d2d230;
}

@media screen and (max-width: 991px) {
  .task-modal {
    width: 750px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .task-modal-wrapper {
    left: 50%;
    width: auto;
    max-width: 100%;
    margin-left: -350px;
  }

  .search-settings-container {
    width: 390px;
  }

  .hero-image {
    width: 350px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 767px) {
  ::-webkit-scrollbar {
    display: none;
  }

  p {
    font-size: 1em;
  }
  h1 {
    font-size: 1.2em;
  }
  .text.medium {
    font-size: 1em;
  }

  .fullscreen {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 1;
    overflow: auto;
    flex-direction: column;
  }

  .left-menu {
    display: none;
  }
  .project-area {
    background-color: var(--dark-blue-alt);
  }
  .dark .project-area {
    background-color: none;
    background: url("https://branzy.club/background_dark.jpg");
  }

  .columns-wrapper {
    padding-bottom: 27px;
  }

  .chat-wrapper {
    position: absolute;
    padding: 0px;
    width: 100%;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
  }

  .projects-container {
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
  }

  .project-card-wrap {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 5px;
  }

  .project-card-wrap:hover {
    box-shadow: none;
  }

  .top-menu {
    padding-right: 10px;
    padding-left: 10px;
    align-items: stretch;
    border-bottom: 1px solid #dbdbdb;
    box-shadow: none;
  }

  .top-menu-nav.mobile-only {
    display: flex;
  }

  .top-menu-nav.center {
    display: flex;
    min-width: 100px;
    justify-content: center;
  }

  /* Испоьзуется для поиска в мобильной версии, который должен закрывать весь экран */
  .top-menu-nav.center.mobile-adoption {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: none;
    padding-top: 10px;
    align-items: stretch;
  }

  .top-menu-nav.center.mobile-adoption.active {
    display: flex;
  }

  .top-menu-nav.start {
    max-width: none;
    flex: 1;
  }

  .top-menu-nav.all-screens {
    display: flex;
    flex: 0 auto;
  }

  .top-menu-search-wrapper {
    width: 0px;
    overflow: hidden;
  }
  .top-menu-search-wrapper.active {
    width: 100%;
  }

  .top-menu-dropdown {
    width: calc(100%-10px);
  }

  .row.vertical-for-mobile {
    flex-direction: column;
  }

  .col.full-screen-on-mobile {
    max-width: 100%;
  }
  .col.hidden-on-mobile {
    display: none;
  }

  .task-modal-wrapper {
    left: 0%;
    right: 0%;
    bottom: 0%;
    top: 0%;
    overflow: hidden;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .task-modal {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 0px;
    /* margin-bottom: 0px; */
    padding-bottom: 0px;
    border-radius: 0px;
  }
  .dark .task-modal {
    border: none;
  }
  .modal-header {
    padding-top: 5px;
    padding-right: 10px;
  }

  .modal-activity {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal-content {
    min-width: auto;
    padding-right: 10px;
    padding-left: 10px;
  }

  .modal-body {
    min-width: auto;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    flex-direction: column;
  }

  .col.scrollable {
    overflow: visible;
  }

  .files-container {
    flex-direction: column;
  }

  .file {
    width: 100%;
    margin-bottom: 5px;
    flex-direction: row;
  }
  .template {
    max-width: 120px;
  }

  .drop-file-container {
    display: none;
  }

  .row.adoptive {
    flex-direction: column;
  }

  .messages-container {
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 120px;
  }

  .modal-close {
    display: flex;
    height: 55px;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    background-color: #fff;
  }

  .modal-activity {
    display: block;
    clear: both;
    flex-direction: column;
    flex: 1;
  }

  .scroll-to-messages {
    bottom: 90px;
    right: 20px;
    width: 40px;
    height: 40px;
  }

  .file-body {
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .content-container {
    position: relative;
    padding-bottom: 50px;
    background-color: transparent;
  }

  .project-card-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .simple-modal {
    width: 100vw;
    max-height: 100svh;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .modal-footer {
    padding-bottom: 20px;
  }

  .login-wrapper {
    width: auto;
    min-height: 100svh;
  }

  .notification-cleaner {
    left: 0;
    right: 0;
    padding: 0px 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
  }

  .bottom-menu {
    position: fixed;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    z-index: 5;
    display: flex;
    overflow: auto;
    border-top: 1px solid #dbdbdb;
    background-color: #fff;
  }
  .dark .bottom-menu {
    background-color: var(--dark-bg);
    border-top: none;
  }

  .bottom-menu-nav {
    display: flex;
    height: 50px;
    padding-top: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: var(--dark-blue-alt);
    font-size: 0.9em;
    text-decoration: none;
  }
  .dark .bottom-menu-nav {
    color: var(--light);
  }

  a.bottom-menu-nav {
    color: var(--dark-blue-alt);
  }

  .search-form {
    flex-direction: column;
    justify-content: flex-start;
  }

  .search-settings-container {
    position: static;
    display: flex;
    width: 100%;
    margin-top: 10px;
    flex-direction: column;
    flex: 0 0 auto;
  }

  .search-cover.active {
    background-color: #eee;
  }
  .dark .search-cover.active {
    background-color: var(--super-dark);
  }

  .search-settings-body {
    flex: 1;
  }

  .search-filter-wrapper {
    display: none;
  }

  .mobile-logo {
    width: 120px;
    height: 35px;
    margin-top: 0px;
    margin-bottom: 0px;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("https://branzy.club/static_files/branzy_logo_icon_black.png");
  }
  .dark .mobile-logo {
    background-image: url("https://branzy.club/static_files/branzy_logo_icon_white.png");
  }

  .modal-topline {
    height: 50px;
    justify-content: flex-start;
  }
  .modal-topline {
    padding-left: 10px;
    padding-right: 10px;
  }
  .ntfc-img {
    width: 30px;
    height: 30px;
    margin-left: 5px;
  }

  .quote {
    display: flex;
  }

  .modal-nav-container.active.mobile-only {
    display: flex;
  }

  .modal-ref-wrapper {
    padding: 0px 10px;
  }

  .modal-ref-scroller {
    display: none;
  }

  .modal-navigation {
    padding-right: 5px;
    padding-left: 5px;
  }

  .modal-navigation::-webkit-scrollbar {
    background-color: transparent;
  }

  .modal-widgets {
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
  }

  .hero-section {
    display: flex;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    flex-direction: column;
  }

  .hero-heading.big {
    font-size: 1.8em;
  }

  .hero-wrapper {
    padding-top: 20px;
    padding-right: 0px;
    padding-left: 0px;
    flex-direction: column;
  }

  .hero-image {
    width: 300px;
    height: 300px;
    margin-right: auto;
    margin-left: auto;
  }

  .hero-button {
    margin-right: auto;
    margin-left: auto;
  }

  .hero-benefits-row {
    flex-direction: column;
  }
  .modal-content-container {
    margin-left: 10px;
    margin-right: 10px;
  }

  .title-wrapper {
    padding-right: 5px;
    padding-left: 5px;
    cursor: pointer;
  }

  .modal-topline-wrapper {
    padding-right: 0px;
    padding-left: 15px;
  }

  .bottom-menu-text {
    font-size: 1em;
  }

  .absolute-container {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 10;
    display: flex;
    flex-direction: column;
  }
  .dark .absolute-container {
    background-color: var(--dark-grey-alt);
  }

  .modal-wrapper {
    left: 0%;
    top: auto;
    margin-left: 0px;
    align-items: flex-end;
  }

  .right-panel.active {
    width: 100vw;
  }

  .login-support-wrapper {
    overflow: visible;
  }

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .send-message-container {
    position: fixed;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0px;
    z-index: 12;
    margin-bottom: 0px;
    background-color: var(--white);
    border-top: 1px solid var(--super-light);
  }
  .dark .send-message-container {
    background-color: var(--dark-bg);
    border-top: none;
  }

  .send-message-container.hide {
    display: none;
  }

  .message-input-wrapper {
    position: relative;
    padding-bottom: 25px;
  }
  .typing-container {
    position: absolute;
    bottom: 5px;
    left: 5px;
  }

  .modal-messages {
    margin-bottom: 110px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .console-container {
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0px;
  }

  .editor-nav-wrapper {
    z-index: 3;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-template-areas: "Area";
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .editor-nav {
    margin-top: 3px;
    margin-right: 3px;
    margin-left: 3px;
  }

  .editor-wrapper.active {
    max-width: 85vw;
    min-height: 100px;
  }

  .settings-container {
    overflow: auto;
    flex-direction: column;
  }

  .task-bubble {
    width: 25px;
    height: 25px;
    z-index: 1;
    font-size: 1em;
    padding-top: 6px;
    padding-left: 6px;
  }
}

@media screen and (max-width: 479px) {
  .projects-container {
    flex-direction: column;
    align-items: stretch;
  }

  .project-card-image {
    width: 130px;
    height: 100px;
    background-size: cover;
  }

  .modal-body {
    flex-direction: column;
  }

  .file {
    padding-right: 5px;
  }

  .simple-modal {
    border-radius: 0px;
  }

  .bottom-menu-nav {
    flex: 1;
  }

  .toast-wrapper {
    margin-top: 10px;
  }

  .task-cover-zone {
    background-color: rgba(0, 0, 0, 0);
  }
}
