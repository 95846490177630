:root{
  --dark: #1f2428;

}
figure {
  margin: 0 auto;
  max-width: 1100px;
  position: relative;
}
.graphic {
  padding-left: 10px;
  padding-right: 10px;
}
.chart-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px;
  position: relative;
  padding: 10px 18px 10px 10px;
  border-radius: 7px;
  background-color: #e6e8f0;
}
.chart-row {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-bottom: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 #cecece;
  border-radius: 7px;
}

.chart {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-right: 5px;
  padding-left: 5px;
  /* animation: expand-chart 1.5s ease forwards; */
}

@keyframes expand-chart {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.chart-row + .chart-row .chart {
  animation-delay: 0.2s;
}
.chart-row + .chart-row + .chart-row .chart {
  animation-delay: 0.4s;
}

.bar {
  justify-content: flex-start;
  height: 15px;
  font-size: 0.75em;
  background-color: #334d5c;
  position: relative;
  z-index: 1;
  opacity: 1;
  transition: opacity, 0.3s ease;
  cursor: pointer;
  border-radius: 3px;
  margin-bottom: 30px;
}

.bar.thin {
  height: 15px;
  border-radius: 5px;
}

.bar-color-0 {
  background-color: #334d5c;
}
.bar-color-1 {
  background-color: #45b29d;
}
.bar-color-2 {
  background-color: #64a2db;
}
.bar-color-3 {
  background-color: #e28563;
}
.bar-color-4 {
  background-color: #f1c163;
}

.bar-color-5 {
  background-color: #2a7b6b;
}
.bar-color-6 {
  background-color: #a74a3d;
}
.bar-color-7 {
  background-color: #962d3e;
}
.bar-color-8 {
  background-color: #b464b2;
}
.bar-color-9 {
  background-color: #3014ba;
}
.bar-color-10 {
  background-color: #4169e3;
}
.bar-color-11 {
  background-color: #2edda3;
}
.bar-color-12 {
  background-color: #cae121;
}
.bar-color-13 {
  background-color: #e6a81a;
}
.bar-color-14 {
  background-color: #b46262;
}
.bar-color-15 {
  background-color: #ab618c;
}
.bar:hover {
  opacity: 0.65;
}
.bar-value {
  display: block;
  line-height: 1em;
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translate(-50%);
}
.dark .bar-value {
  color: #fff;
}
.bar-popover {
  position: fixed;
  z-index: 10;
  min-width: 150px;
  max-width: 200px;
  top: 0;
  left: 0;
  background-color: white;
  color: black;
  padding: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.dark .bar-popover {
  background-color: var(--dark);
  color: white;
}

.bar-popover-cover {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chart-history-container {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 5px;
  display: flex;
}
.chart-history-wrapper {
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 5px;
  margin-right: 10px;
  display: flex;
  cursor: pointer;
}

.chart-history-hidden-checkbox {
  display: none;
}

.chart-history-checkbox {
  position: relative;
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: white;
  border: 1px solid #666;
  border-radius: 2px;
  margin-right: 5px;
}

.chart-history-checkmark {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}

.chart-history-checkmark.checked {
  transform: scale(1);
}

@media screen and (max-width: 767px) {
  .row.chart-row {
    flex-direction: column;
  }
  .col.mobile-full-width {
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 5px;
  }
}
