:root {
  --dark-bg: #22272b;
  /* --dark-bg: #18222d; */
  --dark-bg-alt: #2d3238;
  /* --dark-bg-alt: #22303f; */
  --light: #acb4c0;
  --super-light: #e6e8f0;

  --dark-grey: #3b444d;
  --light-grey: #e4e6e7;
  --light-blue: #8197ce;
  --dark-green: #3c944c;
  --light-green: #6abd79;
  --dark-red: #e25858;
  --light-red: #f8b9b9;
  --dark-yellow: #ffa800;
  --light-yellow: #ffb629;
  --dark-sky: #2485da;
  --light-sky: #3391e4;
  --dark-black: #3a3a3a;
  --light-black: #5a5a5a;
}

/* INPUT TEXT */
.input-wrapper {
  position: relative;
  flex: 1;
  margin: 15px 0 5px 0;
}

.input {
  position: relative;
  margin-bottom: 0px;
  padding-right: 35px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #cacaca;
  color: #293041;
  font-size: 1.1em;
  line-height: 1.1em;
  font-weight: 400;
  z-index: 2;
  background: none;
}

.input:hover {
  border-color: #000 #000 #3b5697;
}

.dark .input {
  background-color: var(--dark-bg-alt);
  border-color: #000 #000 var(--dark-bg-alt);
  color: var(--light);
}
.dark .input:focus {
  outline: none;
}

.input-label {
  position: absolute;
  left: 10px;
  bottom: 7px;
  transition: all 0.2s ease-out;
  z-index: 3;
}

.input-icon-wrapper {
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  bottom: auto;
  display: flex;
  height: 30px;
  margin-top: 5px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  color: var(--dark-sky);
  z-index: 3;
}
.dark .input-icon-wrapper {
  color: var(--light-sky);
}

.input.login-element {
  margin-bottom: 10px;
}
.input.login-element::placeholder {
  color: #67697f;
}
.input.search {
  border: 1px solid #ddd;
  border-radius: 7px;
  background: white;
}

.dark .input {
  border-color: #6a6e75;
  background-color: var(--dark-bg-alt);
}

.input.search:hover,
.input.search:focus {
  border-color: #fff;
  box-shadow: 1px 1px 4px 1px #c7c7c7;
}

.dark .input.search:hover,
.dark .input.search:focus {
  box-shadow: none;
}

.input.search-small {
  height: 28px;
  font-size: 0.9em;
  border: 1px solid #ddd;
  border-radius: 7px;
}
.input.search-small:hover,
.input.search-small:focus {
  border: 1px solid #b1b1b1;
}

.input-popover {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: opacity, 0.3s ease;
}

@media screen and (max-width: 767px) {
  .input-wrapper {
    max-height: 40px;
  }
}

/* BUTTONS */
.button-wrapper {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: flex-end;
  align-items: center;
}

.button-wrapper.center {
  justify-content: center;
}

.button-wrapper.left {
  justify-content: flex-start;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  padding: 0.4em 0.8em;
  border-radius: 8px;
  background-color: #e4e6e7;
  color: #293041;
  font-weight: 400;
  text-decoration: none;
  white-space: nowrap;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: opacity, 0.3s ease;
}

.dark .button {
  background-color: var(--dark-grey);
  color: white;
}

.button:hover {
  background-color: #d4d4d4;
  color: #000;
}

.button:active {
  background-color: #c2c2c2;
}

.button.primary {
  background-color: #3b5697;
  color: #fff;
}

.button.primary:hover {
  background-color: #526dad;
}

.button.primary:active {
  background-color: #253d79;
}

.button.primary.outline {
  border: 1px solid #3b5697;
  background-color: #ffffff00;
  color: #3b5697;
}

.button.primary.outline:hover {
  box-shadow: 1px 1px 2px 0 #8197ce;
}

.button.small {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  font-size: 0.9em;
  line-height: 1em;
}

.button.done {
  background-color: #3c944c;
  color: #fff;
}

.button.done:hover {
  background-color: #348543;
}

.button.done.outline {
  border: 1px solid #31b166;
  background-color: #ffffff00;
  color: #31b166;
}

.button.done.outline:hover {
  border-color: #6abd79;
  box-shadow: 1px 1px 2px 0 #3c944c;
}

.button.big {
  max-width: 300px;
  padding: 10px 25px;
  font-size: 1.2em;
}

.button.danger {
  background-color: #e25858;
  color: #fff;
}

.button.danger:hover {
  background-color: #ec6868;
}

.button.danger:active {
  background-color: #db5454;
}

.button.danger.outline {
  border: 1px solid #e25858;
  background-color: #ffffff00;
  color: #e25858;
}

.button.danger.outline:hover {
  box-shadow: 1px 1px 2px 0 #f8b9b9;
}

.button.warning {
  background-color: #ffa800;
  color: #fff;
}

.button.warning:hover {
  background-color: #ffb629;
}

.button.warning:active {
  background-color: #f1ab24;
}

.button.warning.outline {
  border: 1px solid #dd9700;
  background-color: #ffffff00;
  color: #dd9700;
}

.button.warning.outline:hover {
  box-shadow: 1px 1px 2px 0 #dd9700;
}

.button.sky {
  background-color: #2485da;
  color: #fff;
}

.button.sky:hover {
  background-color: #3391e4;
}

.button.sky:active {
  background-color: #247dca;
}

.button.sky.outline {
  border: 1px solid #2485da;
  background-color: #ffffff00;
  color: #2485da;
  background-color: white;
}

.button.sky.outline:hover {
  box-shadow: 1px 1px 2px 0 #2485da;
}

.button.grey.outline {
  border-style: solid;
  border-width: 1px;
  border-color: #c9ccd4;
  background-color: #ffffff00;
  color: #9297a8;
}
.dark .button.grey.outline {
  border-color: #989ea8;
  color: #989ea8;
}

.button.center {
  margin-right: auto;
  margin-left: auto;
}

.button.load-more {
  margin-top: 10px;
  margin-right: 0px;
  margin-left: 0px;
  background-color: #ffffff00;
  box-shadow: 1px 1px 4px 0 #cecece;
}

.button.black {
  background-color: #3a3a3a;
  color: #fff;
}

.button.black:hover {
  background-color: #5a5a5a;
}

.button.black:active {
  background-color: #353535;
}

.button.black.outline {
  border: 1px solid #2e375f;
  background-color: #ffffff00;
  color: #2e375f;
}

.button.black.outline:hover {
  box-shadow: 1px 1px 2px 0 #b2aaaa;
}

.button.inactive {
  background-color: rgba(228, 230, 231, 0);
  color: #666;
}

.textarea {
  margin-bottom: 0px;
  padding-right: 45px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #bebebe;
  border-radius: 7px;
  background-color: hsla(0, 0%, 100%, 0);
  font-size: 1.1em;
  overflow-y: auto;
  resize: none;
}

.dark .textarea {
  background-color: var(--dark-bg-alt);
  color: var(--super-light) !important;
  border-color: #000 #000 var(--dark-bg-alt);
}

.textarea.heading {
  font-family: Play, sans-serif;
  color: #4b4b4b;
  font-size: 1.3em;
  line-height: 1em;
  font-weight: 700;
}

.texteditor-wrapper {
  position: relative;
}
.texteditor[contenteditable="true"] {
  position: relative;
  margin: 0px;
  overflow-y: auto;
  min-height: 50px;
  max-height: 110px;
  overflow-y: auto;
  padding: 10px 50px 10px 10px;
  border-radius: 10px;
  border: 1px solid var(--light-grey);
  background-color: #fff;
  /* resize: none; */
}

.texteditor[contenteditable="true"]:focus {
  outline: none;
}
.dark .texteditor {
  background-color: var(--dark-bg-alt);
  color: var(--super-light);
  border-color: var(--dark-grey);
}

.texteditor-button {
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.dark .texteditor-button {
  background-color: var(--dark-bg-alt);
  color: var(--light);
}

.texteditor-placeholder {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #aaa;
  pointer-events: none;
}

@media screen and (max-width: 767px) {
  .button {
    padding: 0.6em 0.8em;
    font-size: 1.1em;
  }

  .button.small {
    line-height: 1.2em;
  }

  .button.desktop-only {
    display: none;
  }

  .button.black.outline {
    border-color: #2e375f;
  }
}

.round-button {
  display: flex;
  height: 35px;
  min-width: 35px;
  margin-top: 3px;
  margin-right: 3px;
  padding-right: 10px;
  padding-left: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 19px;
  background-color: #fff;
  cursor: pointer;
  color: #3b5697;
}
.dark .round-button {
  background-color: var(--dark-grey);
  color: white;
}

.round-button:hover {
  box-shadow: 1px 1px 4px 0 #aaa;
  color: #333;
}

.round-button.big {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  font-size: 1.7em;
}

/* Календарь */

.date-picker {
  width: 180px;
  position: relative;
}

.calendar {
  position: fixed;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 25;
  top: 0;
  left: 0;
}

.dark .calendar {
  background-color: var(--dark-bg-alt);
  border-color: #000;
  color: var(--light);
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
}
.dark .calendar-header {
  background-color: var(--dark-bg);
  border-color: #000;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-day {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.calendar-day:hover {
  background-color: #e0e0e0;
}
.dark .calendar-day:hover {
  background-color: var(--dark-black);
}

.selected {
  background-color: var(--dark-sky);
  color: white;
  border-radius: 4px;
}
