.icon {
  text-decoration: none;
  font-size: 1em;
  line-height: 1em;
  position: relative;
  width: 1em;
  height: 1em;
  color: #464f6b;
}
.icon:hover {
  color: #798bb6;
}

.icon:active {
  color: #293041;
}

.dark .icon {
  color: #acb4c0 !important;
}

.icon.small {
  font-size: 1.1em;
  line-height: 1em;
}

.icon.standart {
  font-size: 1.3em;
}

.icon.big {
  font-size: 1.4em;
  line-height: 1em;
}

.icon.blue {
  color: #20abb0;
}
.icon.blue:hover {
  color: #2dcecf;
}

.icon.white {
  color: #e4e4e4;
}
.icon.white:hover {
  color: #ffffff;
}

.icon-alert {
  position: absolute;
  bottom: -3px;
  right: -3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #dd9700;
}

/* .icon.green {
  color: rgb(44, 199, 109);
} */
/* .icon.blinking {
  animation: blink 1s infinite;
} */

.active-icon {
  /* размеры указываю для создания круга при наведении */
  width: 25px;
  height: 25px;
  /* для центрирования иконки внутри блока */
  display: flex;
  /* center */
  justify-content: center;
  align-items: center;
  /* size */
  font-size: 1.2em;
  border-radius: 50%;
  color: #333;
  font-size: 1em;
  cursor: pointer;
}

.active-icon:hover {
  /* add backgroung opacity */
  background-color: #ffffff9d;
}

.active-icon:active {
  background-color: #ebebeb;
}

.active-icon.small {
  font-size: 1em;
  width: 18px;
  height: 18px;
}
.dark .active-icon {
  color: #acb4c0 !important;
}
.dark .active-icon:hover {
  background-color: var(--grey);
}

.active-icon.mobile {
  display: none;
}

.active-icon.grey {
  background-color: #ebebeb;
  color: #858585;
  font-size: 1em;
}
.active-icon.white {
  color: #e4e4e4;
}

.active-icon.grey:hover {
  background-color: #ddd;
}

.active-icon.grey:active {
  background-color: #c9c9c9;
}

.mobile-icon {
  display: flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  color: #424558;
}

.mobile-icon.active {
  color: #2485da;
}

.filled-icon {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  color: #424558;
  cursor: pointer;
}
.filled-icon.text-inline {
  width: 15px;
  height: 15px;
}

.filled-icon:hover {
  color: #2cc76d;
}
.filled-icon.active {
  background-color: #2cc76d;
  color: white;
}

@media screen and (max-width: 767px) {
  .active-icon.desktop {
    display: none;
  }

  .active-icon.mobile {
    display: flex;
  }

  .mobile-icon {
    width: 25px;
    height: 25px;
  }
}
