/* Перенос строки для Description и сообщений */
.break-word {
  white-space: pre-line;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  width: 100%;
}

/* Отключает выделение ссылок синим цветом в мобильном хроме */
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

/* Отключает возможность выделять текст  */
.non-selectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.link-break-all {
  word-break: break-all;
}

.codex-editor__redactor {
  padding-bottom: 150px !important;
}
.codex-editor {
  border-bottom: 1px solid #6195e7;
  padding: 10px;
}

.bottom-menu,
.editor-nav-wrapper,
.send-message-container,
.fullscreen,
.absolute-container {
  padding-bottom: env(safe-area-inset-bottom);
}

.ProseMirror {
  height: 100%;
  outline: none;
}
.ProseMirror s {
  text-decoration: line-through !important;
  color: #666;
}

active-icon {
  transition: opacity, 0.3s ease;
}

.fire-emoji {
  position: absolute;
  bottom: 0;
  font-size: 50px;
  opacity: 0; /* Initially hidden */
}
.letter-emoji {
  position: absolute;
  bottom: 0;
  font-size: 40px;
  opacity: 0; /* Initially hidden */
}
/* Использую для анимирования fire emoji */
@keyframes flyUp {
  from {
    bottom: 0;
  }
  to {
    bottom: 100%;
    opacity: 0;
  }
}
